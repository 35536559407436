<div class="min-h-screen lg:pt-8 lg:px-8 px-0 overflow-auto dark:bg-slate-700 dark:opacity-95 dark:text-white overflow-x-hidden"
  [ngClass]="{'bg-gray-100': !setBodyWhitebackground, 'bg-white dark:bg-slate-800': setBodyWhitebackground}">
  <div class="max-w-[800px] mx-auto rounded-lg pb-6 sm:mb-12" [ngClass]="{
      'px-2 md:px-6': !removePadding,
      'pt-2': !removePadding,
      'px-0': removePadding,
      'pt-0': removePadding,
      'bg-white dark:bg-slate-800':setWhitebackground,
      'shadow-lg': setWhitebackground && !setBodyWhitebackground,
      }" style="min-height: 100vh;">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </div>
</div>

<ng-template #contentTpl><ng-content></ng-content></ng-template>