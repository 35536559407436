import { Routes } from "@angular/router";
import { LoginGuard } from "src/shared/guards/login.guard";

export const ConsultationSetupRoutes: Routes = [
  {
    path: 'consultationConfig',
    loadComponent: () => import('./pages/consultationConfiguration/consultation-configuration.component').then(m => m.ConsultationConfigurationComponent),
    canActivate: [LoginGuard],
    children: [
      {
        path: '',
        redirectTo: 'templates',
        pathMatch: 'full'
      },
      {
        path: "templates",
        loadComponent: () => import('./pages/consultationtemplates/consultation-templates.component').then(m => m.ConsultationTemplatesComponent)
      },
      {
        path: "reasons",
        loadComponent: () => import('./pages/consultationReasonType/consultation-reason-type.component').then(m => m.ConsultationReasonTypeComponent)
      },
      {
        path: "clincialHistory",
        loadComponent: () => import('./pages/consulationClinicalHistory/consultation-clinical-history.component').then(m => m.ConsultationClinicalHistoryComponent)
      },
      {
        path: "clincialPreHistory",
        loadComponent: () => import('./pages/consultation-pre-clinical-history/consultation-pre-clinical-history.component').then(m => m.ConsultationPreClinicalHistoryComponent)
      },
      {
        path: "clincialPostHistory",
        loadComponent: () => import('./pages/consultation-post-clinical-history/consultation-post-clinical-history.component').then(m => m.ConsultationPostClinicalHistoryComponent)
      },
      {
        path: "preExamination",
        loadComponent: () => import('./pages/consultation-pre-examination/consultation-pre-examination.component').then(m => m.ConsultationPreExaminationComponent)
      },
      {
        path: "examination",
        loadComponent: () => import('./pages/consulationExamination/consultation-examination.component').then(m => m.ConsultationExaminationComponent)
      },
      {
        path: "aiNotes",
        loadComponent: () => import('./pages/ai-notes-config/ai-notes-config.component').then(m => m.AiNotesConfigComponent)
      },
      {
        path: "partnerPractices",
        loadComponent: () => import('./pages/partner-practices/partner-practices.component').then(m => m.PartnerPracticesComponent)
      },
      {
        path: "partnerPractices/:id",
        loadComponent: () => import('./pages/partner-practice-edit/partner-practice-edit.component').then(m => m.PartnerPracticeEditComponent)
      },
      {
        path: "handoverReasons",
        loadComponent: () => import('./pages/chat-handover-reasons/chat-handover-reasons.component').then(m => m.ChatHandoverReasonsComponent)
      },
      {
        path: "handoverReasons/:id",
        loadComponent: () => import('./pages/chat-handover-reasons-edit/chat-handover-reasons-edit.component').then(m => m.ChatHandoverReasonsEditComponent)
      }
    ]
  }
];
