import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { SquareImageGridConfig, SquareImageGridItem } from '../../design/squareimagegrid/squareimagegrid.interface';
import { environment } from 'src/environments/environment';
import { RightImagePageWrapperComponent } from '../../design/rightimagepagewrapper/rightimagepagewrapper.component';
import { SqaureImageGrid } from '../../design/squareimagegrid/squareimagegrid.component';
import { injectParams } from 'ngxtension/inject-params';

@Component({
  selector: 'app-tenants',
  templateUrl: './tenants.component.html',
  standalone: true,
  imports: [RightImagePageWrapperComponent, SqaureImageGrid]
})

export class TenantsComponent {

  redirectUrl = injectParams('redirectUrl');

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService
  ) { }

  gridConfig: SquareImageGridConfig = {
    maxcolumns: 2,
    items: []
  }

  ngOnInit() {
    this.gridConfig.items = this.getGridItems();
    this.route.queryParams.subscribe(params => {
      if (params['source'] === 'AFIHUB') {
        this.authenticationService.setTenant('animalfriends');
        this.router.navigate(['register']);
      } else if (params['source'] === 'PURELY') {
        this.authenticationService.setTenant('purelypets');
        this.router.navigate(['register']);
      }
    });
  }

  getGridItems() {
    const tenants = environment.tenants;
    let items: SquareImageGridItem[] = [];
    for (let tenant of tenants) {
      if (!tenant.insurer) {
        //add the button manually in the html
        continue;
      }
      items.push({
        id: tenant.friendly_id,
        dataautomationid: "tenant",
        title: tenant.name,
        image: tenant.icon,
        subtitle: tenant.subtitle
      })
    }
    return items;
  }

  linkClicked(item: SquareImageGridItem) {
    this.authenticationService.setTenant(item.id);
    this.router.navigate([`/${this.redirectUrl() || 'login'}`], { queryParamsHandling: 'preserve' });
  }

  defaultTenantClicked()
  {
    var tenant = environment.tenants.find(x=> x.friendly_id == 'joii');
    this.authenticationService.setTenant(tenant?.id ?? '');
    this.router.navigate([`/${this.redirectUrl() || 'login'}`], { queryParamsHandling: 'preserve' });
  }

}
