import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, input, computed } from '@angular/core';

@Component({
  selector: 'app-pagewrapper',
  templateUrl: './pagewrapper.component.html',
  standalone: true,
  imports: [NgClass, NgTemplateOutlet]
})

export class PageWrapperComponent {
  setWhitebackground = input(true);
  setBodyWhitebackground = input(false);
  removePadding = input(false);
  styleClasses = input('');
  containerStyleClasses = input('');

  combinedStyles = computed(() => `
    ${this.removePadding() ? 'px-0 pt-0' : 'px-2 md:px-6 pt-2'} 
    ${this.setWhitebackground() ? 'bg-white dark:bg-slate-800' : ''} 
    ${this.setWhitebackground() && !this.setBodyWhitebackground() ? 'shadow-lg' : ''} 
    ${this.styleClasses()}
  `)

  combinedContainerStyles = computed(() => `
    ${this.setBodyWhitebackground() ? 'bg-white dark:bg-slate-800' : 'bg-gray-100'} 
    ${this.containerStyleClasses()}
  `)
}
