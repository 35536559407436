import { Component, effect, inject } from "@angular/core";
import { DarkModeService } from "src/shared/services/darkmode/darkmode_service";

@Component({
    selector: 'app-dialog-wrapper',
    standalone: true,
    template: '<ng-content></ng-content>',
})
export class DialogWrapperComponent {
    // component to manipulate the cdk dialog container to enable darkmode
    darkModeService = inject(DarkModeService)
    constructor() {
        effect(() => {
            var overlayContainerClasses = document.getElementsByClassName("cdk-overlay-container")[0].classList;
            if (this.darkModeService.darkModeOn())
                overlayContainerClasses.add('dark')
            else
                overlayContainerClasses.remove('dark')
        })
    }
}