import { CdkDialogContainer, DialogModule } from '@angular/cdk/dialog';
import { Component } from '@angular/core';
import { DialogWrapperComponent } from './dialog-wrapper.component';

@Component({
  selector: 'app-cdk-dialog-container',
  standalone: true,
  imports: [DialogModule, DialogWrapperComponent],
  template: '<app-dialog-wrapper><ng-template cdkPortalOutlet></ng-template></app-dialog-wrapper>',
  styles: [`
    :host {
      display: block;
      background: #FFF;
      border-radius: 0.5rem;
      padding: 1rem;
      overflow-y: auto;
      max-height: calc(100vh - 2rem);
      
    }
    .no-padding{
        padding:0;
      }
    .dark {
      :host{
        --tw-bg-opacity: 1;
        background-color: rgb(30 41 59 / var(--tw-bg-opacity));
      --tw-text-opacity: 1;
      color: rgb(241 245 249 / var(--tw-text-opacity))
    }
  }
  `]
})
export class CdkDialogContainerComponent extends CdkDialogContainer { }
