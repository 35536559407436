import { CdkDialogContainer, DialogModule } from '@angular/cdk/dialog';
import { Component } from '@angular/core';

@Component({
  selector: 'app-cdk-dialog-container',
  standalone: true,
  imports: [DialogModule],
  template: '<ng-template cdkPortalOutlet></ng-template>',
  styles: [`
    :host {
      display: block;
      background: #fff;
      border-radius: 0.5rem;
      padding: 1rem;
      overflow-y: auto;
      max-height: calc(100vh - 2rem);
    }
  `]
})
export class CdkDialogContainerComponent extends CdkDialogContainer {}
