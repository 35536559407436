import * as Sentry from "@sentry/angular";
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { bootstrapApplication } from '@angular/platform-browser';
import { PortalComponent } from './portal/portal.component';
import { portalConfig } from './portal/portal.config';

if (environment.production) {
  Sentry.init({
    dsn: "https://630d919edd9a086c3bcd47921e9dae95@o4506773953773568.ingest.sentry.io/4506773993422848",
    release: `joii@${environment.VERSION}`,
    dist: '1',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration()
    ],
    environment: environment.environment,
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    profilesSampleRate: 1.0
  });

  enableProdMode();
}

bootstrapApplication(PortalComponent, portalConfig)
.catch((err) => console.error(err));