import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { CheckAccessDataService } from 'src/shared/services/checkaccess.data.service/checkaccess.data.service';
import { UserRolesEnum } from 'src/shared/models/interfaces/user-roles.enum';

@Injectable({
    providedIn: 'root'
})
export class TMSAdminGuard implements CanActivate {
    private isAdmin: boolean = false;
    constructor(
        private router: Router,
        private checkAccessDataService: CheckAccessDataService
    ) { }
  
    canActivate(): Observable<boolean> {
        this.isAdmin = this.checkAccessDataService.hasRole(UserRolesEnum.tmsAdministrator)
        if (!this.isAdmin) {
            this.router.navigate(['/trials'])
        }
        return of(this.isAdmin)
        
    }
}