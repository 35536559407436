import { DialogConfig } from "@angular/cdk/dialog";
import { CdkDialogContainerComponent } from "../components/cdk-dialog-container/cdk-dialog-container.component";
import { CdkDialogContainerUnpaddedComponent } from "../components/cdk-dialog-container/cdk-dialog-container-unpadded.component";

export const DefaultDialogConfig: DialogConfig<any, any> = {
    container: CdkDialogContainerComponent,
    hasBackdrop: true,
    panelClass: 'dialog-container',
    backdropClass: 'dialog-backdrop',
};

export const NoPaddingDialogConfig: DialogConfig<any, any> = {
    container: CdkDialogContainerUnpaddedComponent,
    hasBackdrop: true,
    panelClass: 'dialog-container',
    backdropClass: 'dialog-backdrop',
};