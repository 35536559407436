import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, importProvidersFrom, provideZoneChangeDetection } from "@angular/core";
import { provideRouter } from "@angular/router";
import { HttpClient, provideHttpClient } from "@angular/common/http";
import { provideAnalytics, getAnalytics } from "@angular/fire/analytics";
import { provideFirebaseApp, initializeApp } from "@angular/fire/app";
import { provideAuth, getAuth, Auth } from "@angular/fire/auth";
import { provideFirestore, getFirestore } from "@angular/fire/firestore";
import { provideRemoteConfig, getRemoteConfig } from "@angular/fire/remote-config";
import { provideStorage, getStorage } from "@angular/fire/storage";
import { provideAngularSvgIcon } from "angular-svg-icon";
import { environment } from "src/environments/environment";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { HttpLoaderFactory } from "src/shared/translate/custom.translate.loader";
import * as Sentry from '@sentry/angular';
import { provideIcons } from "@ng-icons/core";
import { ICONS, NGICONS } from "src/icons";
import { LucideAngularModule } from "lucide-angular";
import { provideAnimations } from "@angular/platform-browser/animations";
import { provideToastr } from "ngx-toastr";
import { PortalRoutes } from "./portal-routes";
import { SessionStateService } from "src/symptomchecker/services/symptomcheckersession/session-state.service";
import { CtaHandlerService } from "src/symptomchecker/services/cta-handler/cta-handler.service";
import { DEFAULT_DIALOG_CONFIG } from "@angular/cdk/dialog";
import { DefaultDialogConfig } from "src/shared/config/dialog.config";

export const portalConfig: ApplicationConfig = {
    providers: [
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideAnalytics(() => getAnalytics()),
        provideAuth(() => getAuth()),
        provideFirestore(() => getFirestore()),
        provideStorage(() => getStorage()),
        provideRemoteConfig(() => getRemoteConfig()),
        provideHttpClient(),
        provideAngularSvgIcon(),
        provideIcons(NGICONS),
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideAnimations(),
        provideToastr({ timeOut: 10000, positionClass: 'toast-bottom-center' }),
        importProvidersFrom(LucideAngularModule.pick(ICONS)),
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => { },
            deps: [Sentry.TraceService],
            multi: true,
        },
        // This ensures auth is ready to rock and roll before any component is created
        {
            provide: APP_INITIALIZER,
            useFactory: (auth: Auth) => () => new Promise((resolve) => {
                auth.onAuthStateChanged((user) => {
                    // Firebase is ready, regardless of user being logged in or not
                    resolve(true);
                });
            }),
            deps: [Auth],
            multi: true,
        },
        importProvidersFrom(TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },

        })),
        provideRouter(PortalRoutes),
        SessionStateService,
        CtaHandlerService,
        { provide: DEFAULT_DIALOG_CONFIG, useValue: DefaultDialogConfig },
    ]
}