export enum OutcomeCTAEnum {
    Products = 'products',
    VetCall = 'vet-call',
    NurseCall = 'nurse-call',
    VisitVetPractice = 'visit-vet-practice',
    Close = 'close',
    ViewMobilityHistory = 'view-mobility-history',
    StartAgain = 'start-again'
  }

