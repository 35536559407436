import { Routes } from "@angular/router";
import { CheckStaffAccessComponent } from "src/shared/components/authentication/checkstaffaccess/checkstaffaccess.component";
import { ForgottenPasswordComponent } from "src/shared/components/authentication/forgottenpassword/forgottenpassword.component";
import { LoginComponent } from "src/shared/components/authentication/login/login.component";
import { RegisterComponent } from "src/shared/components/authentication/register/register.component";
import { TenantsComponent } from "src/shared/components/authentication/tenants/tenants.component";
import { NotAuthorisedComponent } from "src/shared/components/notauthorised/notauthorised.component";
import { NotFoundComponent } from "src/shared/components/notfound/notfound.component";
import { LoginGuard } from "src/shared/guards/login.guard";
import { SymptomCheckerRoutes } from "./symptomchecker/symptomchecker-routes";
import { ShopRoutes } from "./shop/shop-routes";
import { SecurityRoutes } from "./security/security-routes";
import { SchedulingRoutes } from "./scheduling/scheduling-routes";
import { OnboardingRoutes } from "./onboarding/onboarding-routes";
import { MaintenanceRoutes } from "./maintenance/maintenance-routes";
import { DashboardRoutes } from "./dashboard/dashboard-routes";
import { ConsultationSetupRoutes } from "./consultation-setup/consultation-setup-routes";
import { AccountRoutes } from "./account/account-routes";
import { UsersRoutes } from "./users/users-routes";
import { TrialsRoutes } from "./tms/trials-routes";

export const PortalRoutes: Routes = [
    // Authentication routes
    { path: 'checkaccess', component: CheckStaffAccessComponent, canActivate: [LoginGuard] },
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'forgottenpassword', component: ForgottenPasswordComponent },
    { path: 'tenants/:redirectUrl', component: TenantsComponent },
    { path: 'unauthorised', component: NotAuthorisedComponent },
    // Portal routes
    { path: '', redirectTo: "checkaccess", pathMatch: "full" },
    {
        path: '',
        children: [
            ...SymptomCheckerRoutes,
            ...ShopRoutes,
            ...SecurityRoutes,
            ...SchedulingRoutes,
            ...OnboardingRoutes,
            ...MaintenanceRoutes,
            ...DashboardRoutes,
            ...ConsultationSetupRoutes,
            ...AccountRoutes,
            ...UsersRoutes,
            ...TrialsRoutes,
        ]
    },
    // 404 route (Must be last in list)
    { path: '**', redirectTo: "notfound" },
    { path: 'notfound', component: NotFoundComponent },
];